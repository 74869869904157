import type { NextPage } from 'next';
import Head from 'next/head';
import { PubSub, EVENTS, LANGUAGES, COUNTRIES, API_ENVS } from '@rc-d2c/context-manager';
import { RcButton, RcSelect } from '@rc-d2c/shared-ui';
import AddToCartCard from '../components/AddToCartCard';
import styles from '../styles/common.module.scss';
import { useState } from 'react';

const COUNTRY_LOCALES = {
  FR: LANGUAGES.frFR,
  NL: LANGUAGES.nlNL,
  DE: LANGUAGES.deDE,
  SE: LANGUAGES.svSE,
  TR: LANGUAGES.trTR,
  EN: LANGUAGES.enUS,
  GB: LANGUAGES.enGB,
};

const switchStoreSelectOptions = [
  { value: 'DE', content: 'DE' },
  { value: 'FR', content: 'FR' },
  { value: 'NL', content: 'NL' },
  { value: 'SE', content: 'SE' },
  { value: 'TR', content: 'TR' },
  { value: 'US', content: 'US' },
  { value: 'GB', content: 'GB' },
  // @deprecated [6d5f59e4-4162-4286-a6ae-788520cb127b]
  { value: 'UK', content: 'UK' },
  // deprecated END
];

const Home: NextPage = () => {
  const [store, setStore] = useState<keyof typeof COUNTRIES>('NL');

  const setLanguage = (language: LANGUAGES) => () => {
    PubSub.publish(EVENTS.SET_LANGUAGE, language);
  };

  const switchStore = (e: keyof typeof COUNTRIES) => {
    PubSub.publish(EVENTS.SET_GLOBAL_SETTINGS_CONFIG, {
      country: COUNTRIES[e],
      language: COUNTRY_LOCALES[e as keyof typeof COUNTRY_LOCALES],
      apiEnv: API_ENVS.STG,
      routes: {
        HOMEPAGE: '/',
        CONTACT_SUPPORT: '/',
      },
    });
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>Shell next 13</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={styles.main}>
        <div className={styles.blockWrapper}>
          <h3 className={styles.blockWrapperTitle}>Update language</h3>
          <RcButton onClick={setLanguage(LANGUAGES.enUS)} label={LANGUAGES.enUS} variant="secondary" />
          <RcButton onClick={setLanguage(LANGUAGES.frFR)} label={LANGUAGES.frFR} variant="secondary" />
        </div>
        <div className={styles.blockWrapper}>
          <h3 className={styles.blockWrapperTitle}>Switch Store</h3>
          <RcSelect
            name="store"
            options={switchStoreSelectOptions}
            onChange={({ target: { value } }) => setStore(value as keyof typeof COUNTRIES)}
            value={store}
            label="Store"
          />
          <RcButton onClick={() => switchStore(store)} label={`Switch Store ${COUNTRIES[store].toUpperCase()}`} />
        </div>
        <div>
          <div className={styles.blockWrapper}>
            <h3>Cats</h3>
            <div className={styles.cardContainer}>
              <AddToCartCard
                title="Sterilised 7+"
                payload={{
                  mainItem: '2560',
                  ean: '3182550784511',
                  skuId: '8a7081d4818c62cc018194652f2d04d5',
                  quantity: 1,
                  isSubscriptionChecked: false,
                }}
              />
              <AddToCartCard
                title="Indoor 27"
                payload={{
                  mainItem: '2529',
                  ean: '3182550704625',
                  skuId: '8a7081d4818c62cc0181946504b903c5',
                  quantity: 3,
                  isSubscriptionChecked: true,
                  periodTypeId: '61708',
                }}
              />
            </div>
          </div>
          <div className={styles.blockWrapper}>
            <h3>Dogs</h3>
            <div className={styles.cardContainer}>
              <AddToCartCard
                title="Mini Starter Mother & Babydog"
                payload={{
                  mainItem: '2990',
                  skuId: '8a7081d4818c62cc01819464a3860145',
                  ean: '3182550932707',
                  quantity: 1,
                  isSubscriptionChecked: false,
                }}
              />
              <AddToCartCard
                title="Medium Starter Mother & Babydog"
                payload={{
                  mainItem: '2993',
                  skuId: '8a7081d4818c62cc01819464b41f01ad',
                  ean: '3182550932714',
                  quantity: 1,
                  isSubscriptionChecked: true,
                  periodTypeId: '61711',
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
